<template>

  <div class="text-center">
    <h2>Plus de 900 établissements ont confiance en Blockproof, pourquoi pas vous ?</h2>
    <div class="text-sm lg:text-2xl"><b>94,4%</b> de nos clients choisissent de travailler avec nous dans le temps (statistique 01.2024)</div>
  </div>

  <div class="grid">
    <!-- Testimonial : ANRAS -->
    <div class="mt-8 col-12 lg:col-4">
      <PrimeCard class="mx-auto relative max-w-full w-25rem h-full border-round-3xl shadow-2 bg-primary-50">
        <template #content>
          <PrimeImage src="/img/guillemets-2.svg" alt="blockproof" imageClass="testimonial-quote"></PrimeImage>
          <div class="h-full flex flex-column justify-content-between">
            <div class="mt-4 text-base lg:text-xl">
              Le rapport humain avec Blockproof est très intéressant, il y a une bonne réactivité et une très bonne disponibilité.
            </div>
            <div class="mt-4 flex justify-content-between align-items-center">
              <div class="pr-3">
                <div class="text-lg lg:text-xl font-semibold">ANRAS</div>
                <div class="text-sm lg:text-lg">Responsable juridique</div>
              </div>
              <PrimeImage src="/img/partners/logo-anras.webp" alt="logo" imageClass="w-5rem"></PrimeImage>
            </div>
          </div>
        </template>
      </PrimeCard>
    </div>

    <!-- Testimonial : ApiProvence -->
    <div class="mt-8 col-12 lg:col-4">
      <PrimeCard class="mx-auto relative max-w-full w-25rem h-full border-round-3xl shadow-2 bg-primary-50">
        <template #content>
          <PrimeImage src="/img/guillemets-2.svg" alt="blockproof" imageClass="testimonial-quote"></PrimeImage>
          <div class="h-full flex flex-column justify-content-between">
            <div class="mt-4 text-base lg:text-xl">
              Nous avons été suivis de manière continue et globale par un juriste dédié très disponible.
              Notre niveau de satisfaction est excellent.
            </div>
            <div class="mt-4 flex justify-content-between align-items-center">
              <div class="pr-3">
                <div class="text-lg lg:text-xl font-semibold">API PROVENCE</div>
                <div class="text-sm lg:text-lg">Secrétaire Générale</div>
              </div>
              <PrimeImage src="/img/partners/logo-api_provence.webp" alt="logo" imageClass="w-5rem"></PrimeImage>
            </div>
          </div>
        </template>
      </PrimeCard>
    </div>

    <!-- Testimonial : Artus -->
    <div :class="{'hidden' : props.hideLast}"></div>
    <div class="mt-8 col-12 lg:col-4">
      <PrimeCard class="mx-auto relative max-w-full w-25rem h-full border-round-3xl shadow-2 bg-primary-50">
        <template #content>
          <PrimeImage src="/img/guillemets-2.svg" alt="blockproof" imageClass="testimonial-quote"></PrimeImage>
          <div class="h-full flex flex-column justify-content-between">
            <div class="mt-4 text-base lg:text-xl">
              Blockproof c’est structurant, aidant et efficace.
            </div>
            <div class="mt-4 flex justify-content-between align-items-center">
              <div class="pr-3">
                <div class="text-lg lg:text-xl font-semibold">ARTUS</div>
                <div class="text-sm lg:text-lg">Direction</div>
              </div>
              <PrimeImage src="/img/partners/logo-artus_consulting.webp" alt="logo" imageClass="w-5rem"></PrimeImage>
            </div>
          </div>
        </template>
      </PrimeCard>
    </div>
  </div>

</template>


<script setup lang="ts">
const props = defineProps(['hideLast'])
</script>


<style scoped lang="scss">
// Testimonials
::v-deep(.p-card) {
  .p-card-body {
    padding: 1.5rem;
  }

  .p-card-body, .p-card-content {
    height: 100%;
  }

  .testimonial-quote {
    position: absolute;
    top: -18px;
    left: 37px;
  }
}
</style>
